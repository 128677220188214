<template>
    <form-layout :title="t('COURSES_COURSE_CREATE_COURSE_PAGE_TITLE')" :breadcrumbs="breadcrumbs" @confirm="onConfirm" @cancel="onCancel" :ready="isReady">
        <template #form>
            <course-form v-model="course" v-model:updateEnvironmentEndDates="updateEnvironmentEndDates" :exerciseGroups="exerciseGroups"/>
        </template>
    </form-layout>
    <job-progress-dialog v-model="showJobDialog" :job-id="jobId" :title="t('EDIT_COURSE_UPDATE_ENVIRONMENT_JOB_DIALOG_TITLE')" @confirm="onJobProgressDialogConfirmed" :label="t('EDIT_COURSE_UPDATE_ENVIRONMENT_JOB_ARIA_LABEL')" data-testing="update-exercise-environment-end-date-job-progress"/>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import Route from '../../routers/route';
import FormLayout from '../layouts/FormLayout.vue';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { computed, onMounted, ref } from 'vue';
import { Course, ICourse, IExerciseGroup, InvitationTemplate } from '@cyber-range/cyber-range-api-user-client';
import { useCalendar } from '../../composables/useCalendar';
import { useCourseStore } from '../../stores/courseStore';
import { useRouter } from 'vue-router';
import { useRawObject } from '../../composables/useRawObject';
import CourseForm from './CourseForm.vue';
import { useBusinessUnitStore } from '../../stores/businessUnitStore';
import { useOrganizationStore } from '../../stores/organizationStore';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import { useExerciseGroupStore } from '../../stores/exerciseGroupStore';
import { useJobStore } from '../../stores/jobStore';
import JobProgressDialog from '../jobs/dialogs/JobProgressDialog.vue';
import { useAuthorizationStore } from '../../stores/authorizationStore';

let props = defineProps<{
    courseId:string
}>();

const { t } = useI18n();
const router = useRouter();
const isReady = ref(false);
const { canUpdateCourseAvailability } = useAuthorizationStore();

const jobId = ref("");
const showJobDialog = ref(false);

const breadcrumbs = computed(()=>useCourseBreadcrumbs([ 
                                   new BreadcrumbItem(Route.Courses), 
                                   new BreadcrumbItem({...Route.Course, text: course.value?.name, params: {courseId: props.courseId}}),
                                   new BreadcrumbItem({...Route.EditCourse, params: {courseId: props.courseId}}) ]));

const invitationTemplate = new InvitationTemplate();
invitationTemplate.html = t('COURSE_INVITATION_BODY_HTML');
invitationTemplate.subject = t('COURSE_INVITATION_SUBJECT');
invitationTemplate.text = t('COURSE_INVITATION_BODY_TEXT');

let course = ref<ICourse>(new Course());
let exerciseGroups = ref<IExerciseGroup[]>([]);
let updateEnvironmentEndDates = ref(false);

const leavePage = () => 
{
    //Update the sidebar
    useCourseStore().fetchMyCourses();

    router.push({ name: Route.Course.name,  params: {courseId:props.courseId}});
}

const onConfirm = async () => 
{
    const payloadFields: (keyof ICourse)[] = ['id', 'name', 'description', 'justification'];

    if (canUpdateCourseAvailability(course.value.id, course.value.organizationId))
    {
        payloadFields.push('startTime', 'endTime');
    }
    
    let payload = useRawObject(course.value, {only: payloadFields});

    delete (<any>payload)['image'];
    
    payload.startTime &&= useCalendar().toStartOfDayISOString(payload.startTime);
    payload.endTime &&= useCalendar().toEndOfDayISOString(payload.endTime);

    // Must be done synchronously before checking groups to ensure groups are within new course time bounds
    await useCourseStore().update(payload);

    if (updateEnvironmentEndDates.value && exerciseGroups.value.length)
    {
        showJobDialog.value = true;

        for (let group of exerciseGroups.value)
        {
            const groupPayload = useRawObject(group, {only: ['id']});
            groupPayload.endTime = payload.endTime;
            groupPayload.courseId = props.courseId;

            await useExerciseGroupStore().update(groupPayload, {bulk: true});
        }
        
        jobId.value = await useJobStore().submitJob();
    }
    else
    {
        leavePage();
    }
}

const onCancel = () => 
{
    router.go(-1);
}

onMounted(async ()=>
{
    [course.value, exerciseGroups.value] = await Promise.all([
        useCourseStore().getCourse(props.courseId),
        useExerciseGroupStore().listAllInCourse(props.courseId),
        useOrganizationStore().fetchOrganizations(),
        useBusinessUnitStore().fetchBusinessUnits()
    ]);

    await useOrganizationStore().fetchOrganizationNameAndLogo(course.value.organizationId);

    isReady.value = true
})

const onJobProgressDialogConfirmed = async () =>
{
    jobId.value = "";
    leavePage();
}
</script>