<template>
    <div class="cr-mt4 cr-mb4" data-testing="import-course-users-by-csv-upload-instructions">
        {{t('COURSE_USERS_IMPORT_BY_CSV_FILE_DOWNLOAD')}}
        <a href="/template.csv" download class="cr-external-link" data-testing="import-course-users-by-csv-example-download-link"> {{t('COURSE_USERS_IMPORT_BY_CSV_FILE_ANCHOR')}}</a>
        {{t('COURSE_USERS_IMPORT_BY_CSV_FILE_MODIFY')}}
    </div>
    <cr-file-picker :modelValue="selectedFile" showResult :accept="['.csv']" required class="cr-pt5 cr-mt5 cr-mb3 cr-pb5" @update:modelValue="onCsvSelected"/>
    <cr-error-text v-if="validate && noFileSelected" :message="t('COURSE_USERS_IMPORT_CSV_FILE_REQUIRED_ERROR')" class="error-text" data-testing="import-course-users-by-csv-error-text"/>
</template>

<style>
.error-text
{
    font-size: 0.8rem;
}
</style>

<script setup lang="ts">
    import Papa from 'papaparse';
    import { computed, ref } from 'vue';
    import { useErrorStore } from '../../../stores/errorStore';
    import { useIsEmail } from '../../../composables/useIsEmail';
    import { useI18n } from 'vue-i18n';
    import { UserRole } from '@cyber-range/cyber-range-api-user-client';
    
    const emit = defineEmits<{
        (name: 'usersImported', value:{ name:string, email:string, role:string, team:string }[]): void
    }>();

    const props = defineProps<
    {
        /**
         * Whether or not to check for a selected .csv file and display the validation message.
         * Only show validation message when true.
         * @default false
         */
        validate?: boolean;
    }>();

    const { t } = useI18n();

    const selectedFile = ref<File[]>([]);

    const noFileSelected = computed(():boolean=>
    {
        return selectedFile.value.length === 0;
    });

    const emitSelectedUsersFromText = (csvText:string)=>
    {
        const usersToImport:{name:string, email:string, role:string, team:string}[] = [];

        let results = Papa.parse(csvText);

        let csvRows:string[][];        
       
        if(results.errors?.length > 0)
        {
            throw new Error(t('COURSE_USERS_IMPORT_CSV_ERROR_INVALID_FILE'));
        }
        else
        {
            csvRows = results.data as string[][];
        }
        
        for(let row of csvRows)
        {
            const name:string = row[0]?.trim();
            const email:string = row[1]?.trim();
            let role:string = row[2]?.trim();
            const team:string = row[3]?.trim();

            if([name,email,role].every(field=> !field))
            {
                continue;
            }

            role ||=  UserRole.Student;

            if(!name)
            {
                throw new Error(t('COURSE_USERS_IMPORT_CSV_ERROR_INCOMPLETE_NAME'));
            }

            if(!email)
            {
                throw new Error(t('COURSE_USERS_IMPORT_CSV_ERROR_INCOMPLETE_EMAIL'));
            }

            // ignore headers
            if(name.toLowerCase() === 'name' && email.toLowerCase() === 'email' && role.toLowerCase() === 'role')
            {
                continue;
            }

            if(!useIsEmail(email))
            {
                throw new Error(t('COURSE_USERS_IMPORT_CSV_ERROR_INVALID_EMAIL', {email}).toString())
            }

            usersToImport.push({ name, email, role, team });
        }
        
        if(usersToImport.length === 0)
        {
            throw new Error(t('COURSE_USERS_IMPORT_CSV_ERROR_INCOMPLETE'));
        }
        
        emit('usersImported', usersToImport);
    }

    const clearFileAndSetError = (error:Error) =>
    {
        selectedFile.value = [];
        useErrorStore().setError({message:error.message, details:'', statusCode:0});
    }
    
    const onCsvSelected = (files:File[]|undefined) => 
    {      
        if(files)
        {
            selectedFile.value = files;
            let file = files[0];
            let reader = new FileReader();

            reader.onerror = () =>
            {
                clearFileAndSetError(new Error(t('COURSE_USERS_IMPORT_CSV_ERROR_INVALID_FILE')));            
            }

            reader.onload = () => 
            {
                try 
                {
                    emitSelectedUsersFromText(reader.result as string);
                }
                catch (error)
                {
                    clearFileAndSetError(new Error((error as Error).message));
                }
            }
            reader.readAsText(file);
        }
        else
        {
            selectedFile.value = [];
            emit('usersImported', []);
        }
        return files;
    }
</script>