import { ExerciseGroup, FeatureId, FeatureStatus, IExerciseGroup, INote } from '@cyber-range/cyber-range-api-user-client';
import { defineStore } from 'pinia';
import { useApiClientStore } from './apiClientStore';
import { useRawObject } from '../composables/useRawObject';

export const useExerciseGroupStore = defineStore('exerciseGroupStore', 
{
    actions: 
    {
        async create(exerciseGroup: IExerciseGroup): Promise<string>
        {
            const courseId = exerciseGroup.courseId;
            exerciseGroup = new ExerciseGroup(useRawObject(exerciseGroup, {excludes: ['courseId']}));
            const { userApiClient } = useApiClientStore();
            return await userApiClient.createCourseExerciseGroup(courseId, exerciseGroup);
        },
        async update(exerciseGroup: IExerciseGroup, options?: {bulk?: boolean}): Promise<void>
        {
            const courseId = exerciseGroup.courseId;
            const exerciseGroupId = exerciseGroup.id;
            exerciseGroup = new ExerciseGroup(useRawObject(exerciseGroup, {excludes: ['id','courseId']}));

            const userApiClient = options?.bulk ? useApiClientStore().bulkUserApiClient : useApiClientStore().userApiClient;
            await userApiClient.updateCourseExerciseGroup(courseId, exerciseGroupId, exerciseGroup);
        },

        async listAllInCourse(courseId: string): Promise<IExerciseGroup[]>
        {
            let userApiClient = useApiClientStore().userApiClient;
            return await userApiClient.getAllCourseExerciseGroups({ courseId });
        },
        async getCourseExerciseGroup(courseId: string, exerciseGroupId: string): Promise<IExerciseGroup>
        {
            let userApiClient = useApiClientStore().userApiClient;
            const exerciseGroup = await userApiClient.getCourseExerciseGroup(courseId, exerciseGroupId);
            exerciseGroup.courseId ??= courseId;
            return exerciseGroup;
        },
        async deleteCourseExerciseGroup(courseId: string, exerciseGroupId: string): Promise<void>
        {
            let userApiClient = useApiClientStore().userApiClient;
            await userApiClient.deleteCourseExerciseGroup(courseId, exerciseGroupId);
        },
        async toggleExerciseGroupInterTeamConnectivityControl(exerciseGroup: IExerciseGroup): Promise<void>
        {
            let userApiClient = useApiClientStore().userApiClient;
            const currentStatus = exerciseGroup.features.find(f=>f.id === FeatureId.InterTeamConnectivityControl)?.status;
            let nextStatus = currentStatus === FeatureStatus.Enabled
                ? FeatureStatus.PendingDisabled
                : FeatureStatus.PendingEnabled

            await userApiClient.updateCourseExerciseGroupFeature(exerciseGroup.courseId, exerciseGroup.id, FeatureId.InterTeamConnectivityControl, { status: nextStatus });
        },
        async removeNoteFromExerciseGroup(exerciseGroupId: string, noteId: string): Promise<void>
        {
            let userApiClient = useApiClientStore().userApiClient;
            await userApiClient.removeNoteFromExerciseGroup(exerciseGroupId, noteId);
        }
    }
});
