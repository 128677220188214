import { IFilter } from "@cyber-range/cyber-range-api-client";
import { ref, watch } from "vue";
import Config from "../config";

export function useTableSelectItemsPerPage(filter: Partial<IFilter>, options?: { itemsPerPageOptions?: number[] })
{
    const increasing = (a: number, b:number) => a - b;
    const selectedItemsPerPage = ref<number>();
    const itemsPerPageOptions = ref<number[]>(options?.itemsPerPageOptions?.sort(increasing) || [...Config.DEFAULT_ITEMS_PER_PAGE_OPTIONS]);

    watch(()=>selectedItemsPerPage.value, async ()=> 
    {
        filter.limit = selectedItemsPerPage.value
    })

    const suggestedNumberOfItems = ref<number>(Config.DEFAULT_TILE_LISTING_PAGE_SIZE);
    const onSuggestedNumberOfItems = (n:number) => suggestedNumberOfItems.value = n;

    const initializeItemsPerPage = (suggestedNumber: number = suggestedNumberOfItems.value) =>
    {
        const limit = Number(filter.limit);

        // Offer both filter.limit and suggestedNumberOfItems if they don't already exist
        if (limit && !itemsPerPageOptions.value.includes(limit))
        {
            itemsPerPageOptions.value.push(limit);
            itemsPerPageOptions.value.sort(increasing);
        }
        if (!itemsPerPageOptions.value.includes(suggestedNumber))
        {
            itemsPerPageOptions.value.push(suggestedNumber);
            itemsPerPageOptions.value.sort(increasing);
        }
        
        // To maintain consistency with paging, we want to maintain the query param defined limit as first option
        selectedItemsPerPage.value = filter.limit || suggestedNumber;
    }



    return {
        selectedItemsPerPage,
        itemsPerPageOptions,
        initializeItemsPerPage,
        suggestedNumberOfItems,
        onSuggestedNumberOfItems
    };
}
