<template>
    <cr-alert v-if="showSurvey" info condensed :label="t('INSTRUCTOR_SURVEY_ALERT_LABEL')">
        <a :href="surveyStore.url" target="_blank" @click="onLinkClicked">{{ t('INSTRUCTOR_SURVEY_LINK_TEXT') }}</a>
    </cr-alert>
</template>

<style scoped>
:deep(.cr-alert-label) {
    padding-left: .5rem !important;
    padding-right: 1.5rem !important;
}
</style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useInstructorSurveyStore } from '../../stores/instructorSurveyStore';
import { computed } from 'vue';
const { t } = useI18n()

const surveyStore = useInstructorSurveyStore();
const showSurvey = computed(() => surveyStore.shouldShowSurvey && !surveyStore.hasTakenSurvey)
const onLinkClicked = surveyStore.dismissPermanently
</script>
