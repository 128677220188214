<template>
    <form-section v-if="addingMode && !hasOneOrganizagion" :label="t('COURSE_FORM_ORGANIZATION_SECTION')" data-testing="course-form-organization-section" class="cr-mb5">
        <cr-select v-if="!hasOneOrganizagion" v-model="course.organizationId" :label="t('COURSE_FORM_ORGANIZATION_SECTION_ORGANIZATION')" :disabled="isLoading" :items="allowedOrganizations" required id="create-course-organization-select" data-testing="course-form-organization-input" class="cr-mt3"/>
        <label v-if="hasNoOrganizagions" for="create-course-organization-select" data-testing="course-form-no-organizations-warning" class="cr-error-text">{{ t('COURSE_FORM_ORGANIZATION_SECTION_ERROR_NO_ORGANIZATIONS') }}</label>
    </form-section>
    <business-unit-picker v-if="addingMode && course.organizationId && businessUnit" v-model="businessUnit" :organizationId="course.organizationId" />
    <form-section :label="t('COURSE_FORM_COURSE_INFO_SECTION')" data-testing="course-form-course-info-section" class="cr-mb3">
        <cr-text-field v-model="course.name" :label="t('COURSE_FORM_COURSE_INFO_SECTION_COURSE_NAME')" :disabled="isLoading" required :max-length="128" data-testing="course-form-course-name-input" class="cr-mb3 cr-mt3" />
        <cr-textarea v-model="course.description" :label="t('COURSE_FORM_COURSE_INFO_SECTION_COURSE_DESCRIPTION')" :disabled="isLoading" :rows="5" :max-length="512" required data-testing="course-form-course-description-input" class="cr-mb3" />
        <template v-if="canUpdateCourseAvailability(course.id, course.organizationId)">
            <cr-date-picker v-if="addingMode" v-model="course.startTime" :label="t('COURSE_FORM_COURSE_INFO_SECTION_COURSE_START_DATE')" :disabled="isLoading" class="cr-mb3" :min="now" :max="maxStartDate" data-testing="course-form-course-start-date-input" required />
            <cr-date-picker v-model="course.endTime" @update:modelValue="onEndTimeChanged" :label="t('COURSE_FORM_COURSE_INFO_SECTION_COURSE_END_DATE')" :disabled="isLoading" :min="course.startTime" :max="maxEndDate" data-testing="course-form-course-end-date-input" required />
            <cr-checkbox v-if="showUpdateEnvironmentsCheckbox":modelValue="updateEnvironmentEndDates" @update:modelValue="onUpdateEnvironmentEndDatesChanged" :name="t('COURSE_FORM_COURSE_INFO_SECTION_UPDATE_ENVIRONMENT_END_DATES')" :label="t('COURSE_FORM_COURSE_INFO_SECTION_UPDATE_ENVIRONMENT_END_DATES')"  data-testing="course-form-update-environment-end-dates-checkbox"/>
        </template>
    </form-section>
    <form-section v-if="showJustification" data-testing="course-form-justification-section" :label="t('COURSE_FORM_JUSTIFICATION_SECTION')" class="cr-mt5">
        <p>{{ t('COURSE_FORM_JUSTIFICATION_SECTION_EXPLAINATION') }}</p>
        <cr-textarea v-model="course.justification" :label="t('COURSE_FORM_JUSTIFICATION_SECTION_JUSTIFICATION')" :disabled="isLoading" :rows="5" :max-length="1000" required data-testing="course-form-justification-input" class="cr-mt3"/>
    </form-section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, reactive, ref, watch } from 'vue';
import { CourseStatus, ICourse, IExerciseGroup } from '@cyber-range/cyber-range-api-user-client';
import { useCalendar } from '../../composables/useCalendar';
import { useOrganizationStore } from '../../stores/organizationStore';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../stores/apiClientStore';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import FormSection from '../layouts/sections/FormSection.vue';
import { InternalCustomAttributeName, InternalCustomAttributeValue, Organization } from '@cyber-range/cyber-range-api-organization-client';
import Config from '../../config';
import { useSubscriptionStore } from '../../stores/subscriptionStore';
import { EndDateValue } from '@cyber-range/cyber-range-api-subscription-client';
import { IBusinessUnit } from '@cyber-range/cyber-range-api-business-unit-client';
import BusinessUnitPicker from '../pickers/BusinessUnitPicker.vue';
import { useAnnouncementStore } from '@cyber-range/cyber-range-lib-ui';

let props = defineProps<{
    modelValue:ICourse,
    businessUnit?: IBusinessUnit,
    exerciseGroups?: IExerciseGroup[]
}>();
const emit = defineEmits<{
    (e:'update:modelValue', course:ICourse): void,
    (name: 'update:businessUnit', businessUnit: IBusinessUnit|undefined): void
    (name: 'update:updateEnvironmentEndDates', value: boolean): void
}>();

const { t } = useI18n();
const calendar = useCalendar();
let course = reactive(props.modelValue);

const { organizations } = storeToRefs(useOrganizationStore());
const { isLoading } = storeToRefs(useApiClientStore());
const { canSkipCourseRequest, canSetAnyCourseExpirationDate, canCreateCourse, canUpdateCourseAvailability } = useAuthorizationStore();
const businessUnit = computed<IBusinessUnit|undefined>({
    get: () => props.businessUnit,
    set(value) { emit('update:businessUnit', value); }
});

// Manage state of the checkbox and relay changes back to parent
defineModel<boolean>('updateEnvironmentEndDates');
const onUpdateEnvironmentEndDatesChanged = (value: boolean) => emit('update:updateEnvironmentEndDates', value);

const endTimeChanged = ref(false);
const onEndTimeChanged = () => {
    // Only announce on the first time end time is changed and we expect the checkbox to be shown
    if (!endTimeChanged.value && !addingMode.value && props.exerciseGroups?.length)
    {
        useAnnouncementStore().announce(t('COURSE_FORM_COURSE_INFO_SECTION_UPDATE_ENVIRONMENT_END_DATES_ANNOUNCEMENT'));
    }
    endTimeChanged.value = true;
}
const showUpdateEnvironmentsCheckbox = computed(() => !addingMode.value && endTimeChanged.value && props.exerciseGroups?.length)

const allowedOrganizations = computed(() => organizations.value.filter(org => canCreateCourse([org.id])));

const now = calendar.toYYYYMMDD(new Date());
const endOfSemester = calendar.toYYYYMMDD(calendar.getEndOfSemester(new Date()));
const endOfAcademicyear = calendar.toYYYYMMDD(calendar.getEndOfAcademicYear(new Date()));

const addingMode = computed(()=>!course.id);
const thisOrganizationEndDateSetting = ref<string>(Config.COURSE_MAX_END_TIME_SETTING);
const thisOrganizationExerciseAreaEndDate = ref<string>();

const showJustification = computed(()=> addingMode.value ? !canSkipCourseRequest(course.organizationId) : course.status === CourseStatus.PendingApproval || course.status === CourseStatus.Rejected);

const maxStartDate = computed(()=>course.endTime);
const maxEndDate = computed(()=>
{
    if(canSetAnyCourseExpirationDate(course.organizationId))
    {
        return undefined;
    }
    
    if(thisOrganizationEndDateSetting.value === InternalCustomAttributeValue.Subscription)
    {
        if(thisOrganizationExerciseAreaEndDate.value === EndDateValue.Never)
        {
            //On-going subscription
            return endOfAcademicyear;
        }
        else if(thisOrganizationExerciseAreaEndDate.value)
        {
            //Has active, time-limited subscription
            return thisOrganizationExerciseAreaEndDate.value;
        }
        else
        {
            //No active subscription
            return now;
        }
    }
    else
    {
        return endOfAcademicyear;
    }
});

const hasNoOrganizagions = computed(()=> allowedOrganizations.value.length === 0);
const hasOneOrganizagion = computed(()=> allowedOrganizations.value.length === 1);

watch(hasOneOrganizagion, ()=>
{
    if (hasOneOrganizagion.value)
    {
        course.organizationId = allowedOrganizations.value[0].id;
    }
}, { immediate: true })
watch(()=>({...course}), ()=>
{
    
    emit('update:modelValue', course);
});

const loadForm = async ()=>
{
    if(course.organizationId)
    {
        let thisOrganization = await useOrganizationStore().getOrganization(course.organizationId);
        thisOrganizationEndDateSetting.value = Organization.getInternalCustomAttributeValue(thisOrganization, InternalCustomAttributeName.EnforceCourseEndTimeBy) || Config.COURSE_MAX_END_TIME_SETTING;
        
        if(thisOrganizationEndDateSetting.value === InternalCustomAttributeValue.Subscription)
        {
            thisOrganizationExerciseAreaEndDate.value = (await useSubscriptionStore().getOrganizationSubscriptionEndDates(course.organizationId))?.exerciseArea;
        }
    }

    if(course.startTime)
    {
        course.startTime = calendar.toYYYYMMDD(new Date(course.startTime));
    }
    else
    {
        course.startTime = now;
    }

    if(course.endTime)
    {
        course.endTime = calendar.toYYYYMMDD(new Date(course.endTime));
    }
    else
    {
        course.endTime = endOfSemester
    }
    endTimeChanged.value = false;
}

watch(()=>course.organizationId, loadForm);

loadForm();
</script>