import { defineStore } from "pinia";
import Config from "../config";
import { useAuthorizationStore } from "./authorizationStore";
import { useAnalyticStore } from "./analyticStore";

export const useInstructorSurveyStore = defineStore('instructorSurveyStore',
{
    state: () =>
    ({
        _hasTakenSurvey: undefined as (boolean|undefined),
        /**
         * Temporarily hide instructor survey dialog while store data persists
         */
        showDialog: true
    }),
    getters:
    {
        _isOngoingSurvey(): boolean
        {
            const hasStartDate = !!Config.INSTRUCTOR_SURVEY_START_DATE;
            const hasEndDate = !!Config.INSTRUCTOR_SURVEY_END_DATE

            const startDate = new Date(Config.INSTRUCTOR_SURVEY_START_DATE);
            const endDate = new Date(Config.INSTRUCTOR_SURVEY_END_DATE);
            const now = new Date();

            return (hasStartDate || hasEndDate) // has either a start or end date
                && (!hasStartDate || now > startDate) // if it has a start date: now is after the start date
                && (!hasEndDate || now < endDate); // if it has an end date: now is before the end date
        },
        shouldShowSurvey(): boolean
        {
            return useAuthorizationStore().canViewInstructorSurvey() && !!Config.INSTRUCTOR_SURVEY_URL && this._isOngoingSurvey;
        },
        hasTakenSurvey(): boolean
        {
            return this.shouldShowSurvey && (this._hasTakenSurvey ??= localStorage.getItem("instructorSurvey") === Config.INSTRUCTOR_SURVEY_URL)
        },
        url(): string
        {
            return Config.INSTRUCTOR_SURVEY_URL
        }
    },
    actions: {
        dismissPermanently()
        {
            localStorage.setItem("instructorSurvey", Config.INSTRUCTOR_SURVEY_URL);
            this._hasTakenSurvey = true;
            this.trackGoToSurvey();
        },

        trackDialogShow()
        {
            useAnalyticStore().trackEvent('Instructor Survey', 'Dialog shown');
        },

        trackRemindMeLater()
        {
            useAnalyticStore().trackEvent('Instructor Survey', 'Remind me later clicked');
        },

        trackGoToSurvey()
        {
            useAnalyticStore().trackEvent('Instructor Survey', 'Go to survey clicked');
        },
    }
})
