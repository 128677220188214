import { IApiPageResponse } from '@cyber-range/cyber-range-api-client';
import { IOrganizationUser, IUser, IUserApiClient, OrganizationUserFilter, User, UserApiClient, UserFilter, UserRole } from '@cyber-range/cyber-range-api-user-client';
import { defineStore } from 'pinia';
import { useApiClientStore } from './apiClientStore';

export const useUserStore = defineStore('userStore', 
{
    state: () => 
    ({
        currentUser: undefined as IUser|undefined,
    }),

    actions: 
    {
        async fetchCurrentUser(options?: {background?:boolean}): Promise<IUser>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            const user = await userApiClient.getCurrentUser();
            return this.currentUser = User.fromUserResourceView(user);
        },
        async getUser(userId:string, options?: {background?:boolean}): Promise<IUser>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            const user = await userApiClient.getUser(userId);
            return User.fromUserResourceView(user);
        },


        async listAllUsers(filter?:UserFilter, options?: {background?:boolean}): Promise<IUser[]>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            return userApiClient.listAllUsers(filter);
        },

        async transferCourseTeam(courseId:string, userId:string, fromTeamId:string, toTeamId:string, options?: {background?:boolean}): Promise<void>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            await userApiClient.transferCourseTeam(courseId, toTeamId, fromTeamId, userId);
        },

        async updateCourseUserRole(courseId:string, userId:string, role:UserRole, options?: {background?:boolean}): Promise<void>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            await userApiClient.updateCourseUserRoles(userId, courseId, [role]);
        },

        async update(user:IUser, options?: {background?:boolean}): Promise<void>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            await userApiClient.updateUser(user);
        },


        async updateOrganizationUserRoles(userId:string, organizationId: string, roles:UserRole[], options?: {background?:boolean}): Promise<void>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            await userApiClient.updateOrganizationUserRoles(userId, organizationId, roles);
        },

        async updateUserRoles(userId:string, roles:UserRole[], options?: {background?:boolean}): Promise<void>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            await userApiClient.updateUserRoles(userId, roles);
        },

        async deleteCourseUser(courseId:string, userId:string, options?: {background?:boolean, bulk?:boolean}): Promise<void>
        {
            let userApiClient: IUserApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            userApiClient = options?.bulk ? useApiClientStore().bulkUserApiClient : userApiClient;

            await userApiClient.deleteCourseUser(courseId, userId);
        },

        async deleteOrganizationUser(organizationId: string, userId: string, options?: {background?:boolean}): Promise<void>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            await userApiClient.removeUserFromOrganization(userId, organizationId);
        },

        async listOrganizationUsers(organizationId: string, filter?: OrganizationUserFilter,options?: {background?:boolean}): Promise<IApiPageResponse<IOrganizationUser>>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            return await userApiClient.listOrganizationUsers(organizationId, filter);
        },

        async listAllOrganizationUsers(organizationId: string, filter?: OrganizationUserFilter,options?: {background?:boolean}): Promise<IOrganizationUser[]>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            const users: IOrganizationUser[] = []
            filter = new OrganizationUserFilter({ ...filter, limit: undefined, token: undefined });
            do
            {
                const result = await userApiClient.listOrganizationUsers(organizationId, filter);
                users.push(...result.items);
                filter.token = result.nextPageToken;
            }
            while (filter.token)

            return users;
        },

        async createUserByCtfCompetitionSubdomain(subdomain: string)
        {
            const userApiClient = useApiClientStore().userApiClient;
            await userApiClient.createUserByCtfCompetitionSubdomain(subdomain);
        },
    }
})