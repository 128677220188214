<template>
    <cr-progress-dialog v-model="value" :total="total" :successes="successes" :errors="errors" :persistent="true" :loading="loading" :title="title" :confirm-text="confirmText" @confirm="onConfirm" :data-testing="dataTesting || 'job-progress-dialog'" :progress-label="label"/>
</template>

<script lang="ts" setup>
import { IJob, JobStatus } from '@cyber-range/cyber-range-api-job-client';
import { computed, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useJobStore } from '../../../stores/jobStore';
import { useTimerStore } from '../../../stores/timerStore';

const { t } = useI18n();
const loading = ref<boolean>(true);

const props = defineProps<
{
    jobId: string;
    title: string;
    label: string;
    confirmText?: string;
    dataTesting?: string;
    modelValue?: boolean;
}>();

const emits = defineEmits<{
    (name: 'confirm'|'cancel', ev?:Event): void,
    (name: 'update:modelValue', value: boolean): void
}>();

const value = computed(
    {
        get: () => props.modelValue,
        set: (value: boolean) => emits('update:modelValue', value)
    }
)

const job = ref<IJob>();
const total = computed(() => job.value?.statistics.total ?? 0);
const errors = computed(() => job.value?.statistics.failed ?? 0);
const completed = computed(() => job.value?.statistics.completed ?? 0);
const successes = computed(() => completed.value - errors.value);

const confirmText = computed(() => props.confirmText || t('JOB_PROGRESS_DIALOG_CONFIRM_TEXT'));

let scheduleId: string;

watch(() =>props.jobId, async () =>
{
    loading.value = true;
    if (props.jobId)
    {
        stopPolling();
        await load();
    }
}, {immediate: true})

async function load()
{
    if (props.jobId)
    {
        try
        {
            job.value = await useJobStore().getJob(props.jobId);
            loading.value = false;
            scheduleNextPoll();
        }
        catch
        {
            close();
        }
    }
}

async function scheduleNextPoll() 
{
    try
    {
        if (job?.value?.status === JobStatus.Pending )
        {
            scheduleId = useTimerStore().schedule(load, undefined, 3000, false);
        }
    }
    catch
    {
        close();
    }
};

function stopPolling()
{
    if (scheduleId)
    {
        useTimerStore().unschedule(scheduleId);
    }
}

onUnmounted(() =>
{
    stopPolling();
})

const onConfirm = (ev?: Event) =>
{
    emits('confirm', ev);
}

function close()
{
    loading.value = true;
    stopPolling();
    emits('cancel')
}
</script>