import { defineStore } from 'pinia';
import { IApiPageResponse } from '@cyber-range/cyber-range-api-client';
import { useApiClientStore } from './apiClientStore';
import { OrganizationFilter, IOrganization, IOrganizationFilter, IOrganizationApplicationFilter, IOrganizationApplication, OrganizationSortBy, Organization, ICustomOrganizationLimits, OrganizationLimits, CustomOrganizationLimits } from '@cyber-range/cyber-range-api-organization-client';

export const useOrganizationStore = defineStore('organizationStore', 
{
    state: () => 
    ({
        organizations: <IOrganization[]> [],
        parentOrganizations: <IOrganization[]> [],
        defaultLogo: '/default_org_logo.svg',
        currentOrganizationLogo: "",
        currentOrganizationName: ""
    }),

    actions: 
    {
        async getOrganization(id:string): Promise<IOrganization>
        {
            let organizationApiClient = useApiClientStore().organizationApiClient;

            const organization = await organizationApiClient.getOne(id);

            return organization;
        },

        async fetchOrganizationNameAndLogo(organizationIdOrOrganizations?: string|IOrganization[]): Promise<void>
        {
            // Fetch to transform to an array of orgs if not already
            if (organizationIdOrOrganizations === undefined)
            {
                // Only fetch 2 to confirm if current user is in multiple orgs
                organizationIdOrOrganizations = (await this.listOrganizations(new OrganizationFilter({limit: 2}))).items;
            }
            else if (typeof organizationIdOrOrganizations === "string")
            {
                organizationIdOrOrganizations = [await this.getOrganization(organizationIdOrOrganizations)];
            }

            // Reset current logo if there is not exactly 1 org
            if (organizationIdOrOrganizations.length !== 1)
            {
                this.$state.currentOrganizationLogo = "";
                this.$state.currentOrganizationName = "";
                return;
            }

            // There is just 1 org, so we consider it the current organization
            const organization = organizationIdOrOrganizations[0];

            this.$state.currentOrganizationLogo = organization.logo;
            this.$state.currentOrganizationName = organization.name;
        },

        async listOrganizations(filter?:IOrganizationFilter, options?: {background?:boolean}): Promise<IApiPageResponse<IOrganization>>
        {
            let organizationApiClient = options?.background ? useApiClientStore().backgroundOrganizationApiClient : useApiClientStore().organizationApiClient;

            return await organizationApiClient.get(filter);
        },

        async fetchOrganizations(): Promise<IOrganization[]>
        {
            let result:IOrganization[] = []

            let filter = new OrganizationFilter({limit:Number.MAX_SAFE_INTEGER, sortBy:OrganizationSortBy.Name});

            do
            {
                let page = await this.listOrganizations(filter);
                filter.token = page.nextPageToken;

                result = result.concat(page.items);
            }
            while(filter.token)

            this.organizations = result;

            return result;
        },

        async fetchParentOrganizations(): Promise<IOrganization[]>
        {
            let result:IOrganization[] = []

            let filter = new OrganizationFilter({parentId: "false", limit:Number.MAX_SAFE_INTEGER, sortBy:OrganizationSortBy.Name});

            do
            {
                let page = await this.listOrganizations(filter);
                filter.token = page.nextPageToken;

                result = result.concat(page.items);
            }
            while(filter.token)

            this.parentOrganizations = result;

            return result;
        },

        async create(organization:IOrganization): Promise<string>
        {
            let organizationApiClient = useApiClientStore().organizationApiClient;

            let result = await organizationApiClient.create(organization);

            return result;
        },

        async deleteOrganization(id: string): Promise<void>
        {
            let organizationApiClient = useApiClientStore().organizationApiClient;

            await organizationApiClient.delete(id);
        },

        async update(organization:IOrganization): Promise<void>
        {
            let organizationApiClient = useApiClientStore().organizationApiClient;

            await organizationApiClient.update(organization);
        },

        async setOrganizationParentId(organizationId: string, parentId: string): Promise<void>
        {
            let organizationApiClient = useApiClientStore().organizationApiClient;

            await organizationApiClient.setOrganizationParentId(organizationId, parentId);
        },

        async removeOrganizationParentId(organizationId: string): Promise<void>
        {
            let organizationApiClient = useApiClientStore().organizationApiClient;

            await organizationApiClient.removeOrganizationParentId(organizationId);
        },

        async setOrganizationAlternateId(organizationId: string, alternateId: string): Promise<void>
        {
            let organizationApiClient = useApiClientStore().organizationApiClient;

            await organizationApiClient.setOrganizationAlternateId(organizationId, alternateId);
        },

        async removeOrganizationAlternateIds(organizationId: string): Promise<void>
        {
            let organizationApiClient = useApiClientStore().organizationApiClient;

            await organizationApiClient.removeOrganizationAlternateIds(organizationId);
        },

        async deleteOrganizationCode(organizationId: string): Promise<void>
        {
            let organizationApiClient = useApiClientStore().organizationApiClient;

            await organizationApiClient.deleteOrganizationCode(organizationId);
        },

        async listAllOrganizationApplications(filter?: IOrganizationApplicationFilter, options?: {background?:boolean}): Promise<IOrganizationApplication[]>
        {   
            let organizationApiClient = options?.background ? useApiClientStore().backgroundOrganizationApiClient : useApiClientStore().organizationApiClient;
            
            return await organizationApiClient.getAllOrganizationApplications(filter);
        },

        async deleteOrganizationApplication(organizationId: string, applicationId: string, options?: {background?:boolean}): Promise<void>
        {
            let organizationApiClient = options?.background ? useApiClientStore().backgroundOrganizationApiClient : useApiClientStore().organizationApiClient;

            await organizationApiClient.deleteOrganizationApplication(organizationId, applicationId);
        },

        async updateOrganizationApplication(organizationId: string, applicationId: string, application: IOrganizationApplication, options?: {background?:boolean}): Promise<void>
        {
            let organizationApiClient = options?.background ? useApiClientStore().backgroundOrganizationApiClient : useApiClientStore().organizationApiClient;

            await organizationApiClient.updateOrganizationApplication(organizationId, applicationId, application);
        },

        async createOrganizationApplication(organizationId: string, application: IOrganizationApplication, options?: {background?:boolean}): Promise<void>
        {
            let organizationApiClient = options?.background ? useApiClientStore().backgroundOrganizationApiClient : useApiClientStore().organizationApiClient;

            await organizationApiClient.createOrganizationApplication(organizationId, application);
        },

        async getOrganizationLimits(organizationId: string, options?: {background?:boolean})
        {
            let organizationApiClient = options?.background ? useApiClientStore().backgroundOrganizationApiClient : useApiClientStore().organizationApiClient;

            return await organizationApiClient.getOrganizationLimits(organizationId);
        },
        async updateOrganizationLimits(organizationId: string, limits: Partial<ICustomOrganizationLimits>, options?: {background?:boolean})
        {
            let organizationApiClient = options?.background ? useApiClientStore().backgroundOrganizationApiClient : useApiClientStore().organizationApiClient;

            return await organizationApiClient.updateOrganizationLimits(organizationId, new CustomOrganizationLimits(limits));
        },
    }
})