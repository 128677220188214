<template>
    <cr-navigation-bar :label="t('HEADER_NAVIGATION_BAR_LABEL')" :border="true" :condense="true" height="var(--cr-nav-bar-height)">
        <cr-navigation-bar-title :text="t('HEADER_TITLE')" :image="titleImage" :condense='true'  :to="homeTo"/>
        <cr-navigation-bar-content>
            <cr-spacer />
            <cr-search-bar v-if="searchEnabled && canNavigateOutsideCourse" @search="onSearch" class="cr-mr5" data-testing="header-search" />
            <cr-dropdown v-if="canNavigateOutsideCourse" class="cr-mr5" :label="t('HEADER_ACCOUNT_DROPDOWN')">
                <cr-dropdown-toggle showToggleIcon text text-color="cr-primary-text" background-color="cr-primary">
                    <span>
                        <cr-avatar :src="identity?.avatar" class="cr-mr2"/> {{identity?.name}}
                    </span>
                </cr-dropdown-toggle>
                <cr-dropdown-list>
                    <cr-dropdown-list-item :to="Route.Profile" data-testing="header-profile">{{t('HEADER_ACCOUNT_DROPDOWN_PROFILE')}}</cr-dropdown-list-item>
                    <cr-dropdown-list-divider />
                    <cr-dropdown-list-item :href="useKbStore().home()" data-testing="header-knowledgebase">{{t('HEADER_ACCOUNT_DROPDOWN_KNOWLEDGE_BASE')}}</cr-dropdown-list-item>
                    <cr-dropdown-list-item :to="Route.Support" data-testing="header-support">{{t('HEADER_ACCOUNT_DROPDOWN_CUSTOMER_SUPPORT')}}</cr-dropdown-list-item>
                    <cr-dropdown-list-divider />
                    <cr-dropdown-list-item @click="onLogoutClicked" data-testing="header-logout">{{t('HEADER_ACCOUNT_DROPDOWN_LOGOUT')}}</cr-dropdown-list-item>
                </cr-dropdown-list>
            </cr-dropdown>
            <div v-else class="cr-mr5" data-testing="header-profile">
                <cr-avatar :src="identity?.avatar" class="cr-mr2"/> {{identity?.name}}
            </div>
        </cr-navigation-bar-content>
    </cr-navigation-bar>
</template>

<style scoped>
.cr-dropdown-toggle:not(.show)
{
    color: var(--cr-text);
}
.cr-dropdown-toggle:active,
.cr-dropdown-toggle:hover,
.cr-dropdown-toggle:focus-visible
{
    color: var(--cr-primary-text)
}
.cr-searchbar
{
    width: 15rem;
}
.cr-searchbar, .cr-searchbar:deep(input)
{
    background-color: var(--cr-main-background-color);
}
</style>

<script setup lang="ts">
import { useSessionStore } from '../stores/sessionStore';
import { useKbStore } from '../stores/kbStore';
import Config from '../config';
import { useI18n } from 'vue-i18n';
import Route from '../routers/route';
import { RouteLocationRaw, useRouter } from 'vue-router';
import { useAuthorizationStore } from '../stores/authorizationStore';
import { computed } from 'vue';
import { useOrganizationStore } from '../stores/organizationStore';

const sessionStore = useSessionStore();
const identity = sessionStore.identity;
const router = useRouter();

const { t } = useI18n();

const searchEnabled = Config.FEATURE_SEARCH_ENABLED;
const canNavigateOutsideCourse = useAuthorizationStore().canNavigateOutsideCourse();
const homeTo: RouteLocationRaw = canNavigateOutsideCourse ? Route.Home : Route.Course;

const onSearch = (keyword:string) =>
{
    router.push({...Route.Search, query: {q: keyword}})
}
const onLogoutClicked = () => sessionStore.logout();

const titleImage = computed(() => useOrganizationStore().$state.currentOrganizationLogo || Config.DEFAULT_LOGO_URL);
</script>