<template>
    <cr-select v-for="parameter in selectableRecipeParameters" :key="`${parameter.id}-${parameterSelectKey}`" :label="parameter.name" item-value="value" item-label="name" :items="parameter.options" :model-value="selectedParameters[parameter.id]" @update:model-value="setSelectedParameter(parameter.id, $event)" required :disabled="isLoading"/>
    <cr-confirmation-dialog :model-value="!!pendingChange" @confirm="onPendingChangeConfirmed" @cancel="onPendingChangeCanceled" data-testing="change-confirmation-dialog">
        {{ onChangePrompt }}
    </cr-confirmation-dialog>
</template>

<script setup lang="ts">
import { FamilyRecipeParameter, IFamilyRecipeParameter, IRecipeParameterOption } from "@cyber-range/cyber-range-api-catalog-client";
import { storeToRefs } from 'pinia';
import { computed, nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useApiClientStore } from "../../stores/apiClientStore";

const props = defineProps<{
    recipeParameters: IFamilyRecipeParameter[]
    modelValue?: string
    onChangePrompt?: string
}>();
const emit = defineEmits<{
    (e:'update:modelValue', catalogEntryId:string): void,
}>();

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const parameterSelectKey = ref(0);

const selectedParameters = ref<Record<IFamilyRecipeParameter['id'],IRecipeParameterOption['value']>>({});

watch(() => selectedParameters.value['catalogid'], () =>
{
    emit('update:modelValue', selectedParameters.value['catalogid']);
});

// Checks that a recipe parameter's when requirement is met
const isValidWhen = ({ when }: IFamilyRecipeParameter) => !when || Object.entries(when).every(([key,value]) => selectedParameters.value[key] === value)

const recipeParameters = computed(() =>
{
    const parameters: IFamilyRecipeParameter[] = [];
    for (let parameter of props.recipeParameters || [])
    {
        parameter = new FamilyRecipeParameter(parameter);
        if (!isValidWhen(parameter)) continue;

        // Append 'suggested' to the default parameter option
        parameter.options = parameter.options?.map(option =>
        {
            if (option.value === parameter.default)
            {
                option = {
                    name: option.name +  t('EXERCISE_GROUP_CREATE_CATALOG_FAMILY_SUGGESTED_PARAMETER_SUFFIX'),
                    value: option.value
                }
            }
            return option;
        })
        parameters.push(parameter);
    }
    return parameters;
});

// Only show select component for parameters with options
const selectableRecipeParameters = computed(() => recipeParameters.value.filter(parameter => parameter.options && !(parameter.options.length === 1 && parameter.options[0].value === parameter.default)));

// When displayed recipe parameters change, update selectedParameters accordingly
watch(() => recipeParameters, () =>
{
    // A temporary object is used so selectedParameters doesn't change until all recipe parameters have been iterated over, and so that no longer used keys aren't kept around
    const tempSelectedParameters: Record<IFamilyRecipeParameter['id'],IRecipeParameterOption['value']> = {};

    for (const parameter of recipeParameters.value)
    {
        // Use current selected value if it exists in the currently displayed parameter's options. This won't be the case where parameters with the same id but different options swap visibility due making a different selection for a prior parameter
        let selectedValue = parameter.options?.find(option => option.value === selectedParameters.value[parameter.id])?.value;
        // Otherwise select the default value
        selectedValue ||= parameter.default;
        tempSelectedParameters[parameter.id] = selectedValue;
    }

    // Don't set selectedParameters if nothing has changed
    if (JSON.stringify(selectedParameters.value) !== JSON.stringify(tempSelectedParameters))
    {
        selectedParameters.value = tempSelectedParameters;
    }
}, { immediate: true, deep: true });

const pendingChange = ref<Record<string,string>>();
async function setSelectedParameter(key: IFamilyRecipeParameter['id'], value: IRecipeParameterOption['value'])
{
    if (props.onChangePrompt)
    {
        pendingChange.value = { [key]: value };
    }
    else
    {
        selectedParameters.value[key] = value;
    }
}
function onPendingChangeConfirmed()
{
    Object.assign(selectedParameters.value, pendingChange.value);
    pendingChange.value = undefined;
}
function onPendingChangeCanceled()
{
    pendingChange.value = undefined
    // Force re-render so cr-select modelValue and native html select value are in sync since html select was visually updated before being prevented here
    parameterSelectKey.value++;
};
</script>
