<template>
    <form-layout :title="catalogFamily?.name" :breadcrumbs="breadcrumbs" :ready="ready" :cancel-text="t('EXERCISE_GROUP_CREATE_BACK_BUTTON')" @cancel="onCancel" @confirm="onConfirm" :confirm-disabled="disableEntryInputs">
        <template #form>
            <cr-alert v-if="catalogEntry && !hasRequiredSubscription && !isLoading" warn :label="t('EXERCISE_GROUP_CREATE_CATALOG_ENTRY_MISSING_REQUIRED_SUBSCRIPTION_LABEL')" class="cr-mb2">{{ t('EXERCISE_GROUP_CREATE_CATALOG_ENTRY_MISSING_REQUIRED_SUBSCRIPTION_MESSAGE') }}</cr-alert>
            <cr-alert v-if="isPending" warn class="cr-mb2">{{ t('EXERCISE_GROUP_CREATE_CATALOG_ENTRY_PENDING_ALERT') }}</cr-alert>
            <cr-alert v-if="isError" error class="cr-mb2">{{ t('EXERCISE_GROUP_CREATE_CATALOG_ENTRY_ERROR_ALERT') }}</cr-alert>
            <catalog-notes v-if="catalogEntry?.notes" :notes="catalogEntry?.notes" />
            <form-info-section>
                <div class="mb-3 family-info-container">
                    <cr-image v-if="logo" :src="logo" alt="" max-height="8rem" class="cr-mr3"/>
                    <div class="family-info">
                        <h2 class="family-name cr-mt2">{{ t('EXERCISE_GROUP_CREATE_FAMILY_DESCRIPTION_LABEL') }}</h2>
                        <p>{{ catalogFamily?.description }}</p>
                        <catalog-entry-id-picker v-if="catalogFamily?.recipeParameters" v-model="catalogEntryId" :recipe-parameters="catalogFamily.recipeParameters" :on-change-prompt="catalogEntryIdChangePrompt"/>
                    </div>
                </div>
            </form-info-section>
            <form-section :label="t('EXERCISE_GROUP_FORM_SECTION_TITLE_EXERCISE_ENVIRONMENT_INFORMATION')" class="cr-mt5">
                <cr-text-field :label="t('EXERCISE_GROUP_CREATE_NAME_LABEL')" v-model="name" required :min-length="1" :max-length="250" :disabled="disableEntryInputs" @update:model-value="onNameChange" data-testing="create-exercise-group-name-input"/>
                <cr-textarea :label="t('EXERCISE_GROUP_CREATE_DESCRIPTION_LABEL')" v-model="description" :max-length="5000" :rows="8" :disabled="disableEntryInputs" @update:model-value="onDescriptionChange" data-testing="create-exercise-group-description-input"/>
            </form-section>
            <form-detail-section v-if="variationsInfoText || accessibilityInfoText || catalogEntry?.instructions?.length" class="cr-mt5" data-testing="create-exercise-group-details-section">
                <form-detail-section-item v-if="variationsInfoText" :label="t('EXERCISE_GROUP_CREATE_DETAILS_SECTION_LABEL_VARIATIONS')">{{ variationsInfoText }}</form-detail-section-item>
                <form-detail-section-item v-if="accessibilityInfoText" :label="t('EXERCISE_GROUP_CREATE_DETAILS_SECTION_LABEL_ACCESSIBILITY')">{{ accessibilityInfoText }}</form-detail-section-item>
                <form-detail-section-item v-if="catalogEntry?.instructions?.length" :label="t('EXERCISE_GROUP_RESOURCES')" data-testing="exercise-group-resources">
                    <catalog-resource-links :catalog-entry="catalogEntry"/>
                </form-detail-section-item>
            </form-detail-section>
            <exercise-group-availability v-if="course" v-model:start-time="startTime" v-model:end-time="endTime" :course="course" :disabled="disableEntryInputs" data-testing="create-exercise-group-availability" />
            <form-section v-if="catalogEntry?.recipeParameters && organizationId" v-show="showPersistence" :label="t('EXERCISE_GROUP_CREATE_SECTION_TITLE_PERSISTENCE')" class="cr-mt5" data-testing="create-exercise-group-persistence-section">
                <catalog-entry-persistence v-model="selectedPersistenceValue" v-model:show-persistence="showPersistence" :recipe-parameters="catalogEntry.recipeParameters" :organization-id="organizationId" :disabled="disableEntryInputs"/>
            </form-section>
            <form-section v-if="showAdvancedOptions" :label="t('EXERCISE_GROUP_FORM_ADVANCED_OPTIONS')" class="cr-mt5" data-testing="create-exercise-group-advanced-options-section">
                <cr-checkbox v-if="!!catalogAutoUpdateRecipeParameter" v-model="autoUpdateValue" :disabled="disableEntryInputs" :name="t('EXERCISE_GROUP_FORM_AUTOUPDATE_CTF_SNAPSHOT')" :label="t('EXERCISE_GROUP_FORM_AUTOUPDATE_CTF_SNAPSHOT')" data-testing="create-exercise-group-autoupdate-checkbox"/>
            </form-section>
        </template>
    </form-layout>
</template>

<style scoped>
.family-info-container {
    display: flex;
    flex-wrap: wrap;
}
.family-info {
    flex-grow: 1;
    flex-basis: 12rem;
}
.family-name {
    font-size: var(--cr-section-title-font-size);
}
</style>

<script setup lang="ts">
import { FeatureType, ICatalogEntry, ICatalogFamily, catalogEntryStatus } from '@cyber-range/cyber-range-api-catalog-client';
import { ExerciseGroup, ICourse, RecipeParameter } from '@cyber-range/cyber-range-api-user-client';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import Route from '../../routers/route';
import { useCatalogStore } from '../../stores/catalogStore';
import { useCourseStore } from '../../stores/courseStore';
import { useExerciseGroupStore } from '../../stores/exerciseGroupStore';
import FormLayout from '../layouts/FormLayout.vue';
import FormSection from '../layouts/sections/FormSection.vue';
import FormInfoSection from '../layouts/sections/FormInfoSection.vue';
import FormDetailSection from '../layouts/sections/FormDetailSection.vue';
import FormDetailSectionItem from '../layouts/sections/FormDetailSectionItem.vue';
import CatalogEntryIdPicker from './CatalogEntryIdPicker.vue';
import CatalogEntryPersistence from './CatalogEntryPersistence.vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../stores/apiClientStore';
import ExerciseGroupAvailability from './ExerciseGroupAvailability.vue';
import CatalogNotes from './CatalogNotes.vue';
import { useTimerStore } from '../../stores/timerStore';
import { useSubscriptionStore } from '../../stores/subscriptionStore';
import { Products } from '@cyber-range/cyber-range-api-subscription-client';
import { useScheduler } from '../../composables/useScheduler';
import Config from '../../config';
import { useAuthenticationStore } from '../../stores/authenticationStore';
import { useOrganizationStore } from '../../stores/organizationStore';
import CatalogResourceLinks from './CatalogResourceLinks.vue';

const { t } = useI18n();
const router = useRouter();

const props = defineProps<
{
    courseId: string;
    catalogFamilyId: string;
}>();

const catalogStore = useCatalogStore();
const courseStore = useCourseStore();
const { isLoading } = storeToRefs(useApiClientStore());

const ready = ref(false);
const catalogFamily = ref<ICatalogFamily|undefined>(undefined);
const catalogEntryId = ref('');
const catalogEntry = ref<ICatalogEntry>();
const course = ref<ICourse>();
const name = ref('');
const description = ref('');
const organizationId = computed(() => course.value?.organizationId);
const isPending = computed(() => catalogEntry.value && catalogEntry.value.status !== catalogEntryStatus.READY && catalogEntry.value.status !== catalogEntryStatus.ERROR);
const isError = computed(() => catalogEntry.value && catalogEntry.value.status === catalogEntryStatus.ERROR);
const catalogAutoUpdateRecipeParameter = computed(() => catalogEntry.value?.recipeParameters?.find(rp => rp.id === "autoupdate"));
const autoUpdateValue = ref(false);
const showAdvancedOptions = computed(() => !!catalogAutoUpdateRecipeParameter.value);

watch(organizationId, async () =>
{
    if (organizationId.value)
    {
        useSubscriptionStore().fetchOrganizationSubscribedProducts(organizationId.value);
    }
}, { immediate: true });
const hasRequiredSubscription = computed<boolean>(() => !!organizationId.value && (!catalogEntry.value?.dependsOnProducts || catalogEntry.value.dependsOnProducts.every(product => useSubscriptionStore().isSubscribedTo(organizationId.value!, product as Products))));

const disableEntryInputs = computed<boolean>(() => !catalogEntryId.value || isPending.value || isError.value || !hasRequiredSubscription.value || isLoading.value);

const startTime = ref('');
const endTime = ref('');

const showPersistence = ref(false);
const selectedPersistenceValue = ref('');

const logo = computed(() => catalogEntry.value?.metadata?.logo || catalogFamily.value?.logo);

const variationsInfoText = ref<string>();
const accessibilityInfoText = ref<string>();

const catalogEntryIdChangePrompt = ref("");
function showPromptOnCatalogEntryIdChange()
{
    catalogEntryIdChangePrompt.value = t('EXERCISE_GROUP_CREATE_CATALOG_ENTRY_CHANGE_PROMPT');
}
const onNameChange = showPromptOnCatalogEntryIdChange;
const onDescriptionChange = showPromptOnCatalogEntryIdChange;

watch(() => props.catalogFamilyId, async () =>
{
    ready.value = false;
    catalogFamily.value = await catalogStore.getCatalogFamily(props.catalogFamilyId);
    ready.value = true
}, { immediate: true });

watch(()=>props.courseId, async () =>
{
    course.value = await courseStore.getCourse(props.courseId);
    await useOrganizationStore().fetchOrganizationNameAndLogo(course.value?.organizationId);
}, { immediate: true });

watch(() => catalogEntryId.value, async() =>
{
    if (!catalogEntryId.value)
    {
        return;
    }
    catalogEntry.value = await catalogStore.fetch(catalogEntryId.value);
    catalogEntryIdChangePrompt.value = ""
    name.value = catalogEntry.value?.name || '';
    description.value = catalogEntry.value?.summary || '';
    variationsInfoText.value = catalogEntry.value?.metadata?.variations?.join(', ') || '';
    accessibilityInfoText.value = catalogEntry.value?.features?.filter(f => f.type === FeatureType.Accessibility).map(f => f.name).join('\n');
    autoUpdateValue.value = catalogAutoUpdateRecipeParameter.value?.default === "true";
});

const scheduler = useScheduler()
let pollingId: string
watch(isPending, async () =>
{
    if (isPending.value)
    {
        if (pollingId)
        {
            scheduler.unschedule(pollingId);
        }

        pollingId = scheduler.schedule(async () =>
        {
            catalogEntry.value = await catalogStore.fetch(catalogEntryId.value, { force: true, background: true });
        }, undefined, { frequencyInMs: 5000, repeat: true });
    }
    else
    {
        scheduler.unschedule(pollingId);
    }
});

async function onConfirm()
{
    const exerciseGroupRecipeParameters = [];
    if (selectedPersistenceValue.value)
    {
        const persistenceRecipeParameter = new RecipeParameter();
        persistenceRecipeParameter.id = 'persistent';
        persistenceRecipeParameter.value = selectedPersistenceValue.value;
        exerciseGroupRecipeParameters.push(persistenceRecipeParameter);
    }

    if (catalogAutoUpdateRecipeParameter.value)
    {
        const groupAutoUpdateRecipeParameter = new RecipeParameter();
        groupAutoUpdateRecipeParameter.id = 'autoupdate';
        groupAutoUpdateRecipeParameter.value = String(autoUpdateValue.value);
        exerciseGroupRecipeParameters.push(groupAutoUpdateRecipeParameter);
    }

    const exerciseGroup = new ExerciseGroup({
        name: name.value,
        description: description.value,
        catalogId: catalogEntryId.value,
        courseId: props.courseId,
        endTime: endTime.value,
        startTime: startTime.value,
        recipeParameters: exerciseGroupRecipeParameters,
    });

    await useExerciseGroupStore().create(exerciseGroup);
    router.push({ name: Route.Course.name, params: { courseId: props.courseId } });
}
function onCancel()
{
    router.go(-1)
}

const breadcrumbs = computed(()=>useCourseBreadcrumbs([
    new BreadcrumbItem(Route.Courses), 
    new BreadcrumbItem({...Route.Course,  text: course.value?.name, params: {courseId: props.courseId}}),
    new BreadcrumbItem(Route.CreateExerciseGroup),
    new BreadcrumbItem({...Route.CreateExerciseGroupStep2, text: catalogFamily.value?.name }),
]));
</script>
